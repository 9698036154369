
import PartnersSupportClient from '@/utils/partnersSupportClient';
import { makeProductsUrlByCountry, makeProductsUrlByGrape } from '@/utils/urlGenerator';

const genericNav = [
  {
    id: 'supporting-pages',
    pages: [
      {
        title: 'Home',
        href: '/',
      },
      {
        title: 'Meet Winemakers',
        href: '/brands',
      },
      {
        title: 'Search wines',
        href: '/wines',
      },
      {
        title: 'Search spirits',
        href: '/spirits',
      },
      {
        title: 'Find your perfect wine',
        href: '/quiz',
      },
      {
        title: 'Frequently Asked Questions',
        href: '/faq',
      },
      {
        title: 'About Us',
        href: '/about',
      },
      {
        title: 'Contact Us',
        href: '/contact',
      },
    ],
  },
  {
    id: 'search-wine-by-country',
    title: 'Search wines by country',
    pages: [
      {
        title: 'United States',
        href: makeProductsUrlByCountry('United States'),
      },
      {
        title: 'France',
        href: makeProductsUrlByCountry('France'),
      },
      {
        title: 'Italy',
        href: makeProductsUrlByCountry('Italy'),
      },
      {
        title: 'Chile',
        href: makeProductsUrlByCountry('Chile'),
      },
      {
        title: 'Argentina',
        href: makeProductsUrlByCountry('Argentina'),
      },
      {
        title: 'Australia',
        href: makeProductsUrlByCountry('Australia'),
      },
      {
        title: 'Israel',
        href: makeProductsUrlByCountry('Israel'),
      },
      {
        title: 'South Africa',
        href: makeProductsUrlByCountry('South Africa'),
      },
      {
        title: 'Spain',
        href: makeProductsUrlByCountry('Spain'),
      },
    ],
  },
  {
    id: 'search-wine-by-grape',
    title: 'Search wines by grape',
    pages: [
      {
        title: 'Cabernet Sauvignon',
        href: makeProductsUrlByGrape('Cabernet Sauvignon'),
      },
      {
        title: 'Merlot',
        href: makeProductsUrlByGrape('Merlot'),
      },
      {
        title: 'Cabernet Franc',
        href: makeProductsUrlByGrape('Cabernet Franc'),
      },
      {
        title: 'Petit Verdot',
        href: makeProductsUrlByGrape('Petit Verdot'),
      },
      {
        title: 'Blend',
        href: makeProductsUrlByGrape('Blend'),
      },
    ],
  },
];
const pagesByPartner = {
  [PartnersSupportClient.PARTNER_ID_DANDY_WINE_SHOP]: genericNav,
  [PartnersSupportClient.PARTNER_ID_BEST_WINE_21]: genericNav,
  [PartnersSupportClient.PARTNER_ID_COASTERS]: genericNav,
  [PartnersSupportClient.PARTNER_ID_SBG]: genericNav,
  [PartnersSupportClient.PARTNER_ID_SIPJOY_OVER_BEST_WINE_21]: genericNav,
  [PartnersSupportClient.PARTNER_ID_LEO_HILLINGER]: [
    {
      id: 'supporting-pages',
      pages: [
        {
          title: 'About Us',
          href: '/',
        },
        {
          title: 'Contact Us',
          href: '/',
        },
      ],
    },
  ],
  [PartnersSupportClient.PARTNER_ID_SBG]: [
    {
      id: 'supporting-pages',
      pages: [
        {
          title: 'About Us',
          href: '/',
        },
        {
          title: 'Contact Us',
          href: '/',
        },
      ],
    },
  ]
}
export const pages = pagesByPartner[PartnersSupportClient.getPartnerId()] || [
  {
    id: 'supporting-pages',
    pages: [
      {
        title: 'Home',
        href: '/',
      },
      {
        title: 'Meet Winemakers',
        href: '/brands',
      },
      {
        title: 'Shop wines',
        href: '/wines',
      },
      {
        title: 'Find your perfect wine',
        href: '/quiz',
      },
      {
        title: 'Frequently Asked Questions',
        href: '/faq',
      },
      {
        title: 'Contact Us',
        href: '/contact',
      },
    ],
  },
];
