import {useEffect} from 'react';

import Layout from '@/src/layouts/Main';
import AOS from 'aos';
import getTheme from '@/src/theme';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { isProdEnv } from '@/utils/environment';
import Affiliate from '@/utils/affiliate';
import PartnersSupportClient from '@/utils/partnersSupportClient';
import { useRouter } from 'next/router';
import { AgeGate } from '@/src/components/AgeGate';
import { SiteContext } from '@/src/contexts/SiteContext';
import { Analytics } from '@vercel/analytics/react'
import { GOODFRIENDS } from '@/consts/siteConsts';
import Script from 'next/script'

export default function SipjoyAppContext({
  siteContext,
  children,
  topBarLogo,
  topBarLogoUrl,
  hideTopBar: hideTopBarProp,
  topbarStyle,
  hideFooter,
  themeSiteKey,
  disableAgeGate,
}) {
  const router = useRouter()
  const storageListener = (e) => {
    if (e.key === 'cart' && Cart) {
      Cart.notifyListenersOnChange()
    }
  }

  useEffect(() => {
    if (!router.isReady) return

    if (router.query.pid) {
      Affiliate.setAffiliate(router.query.pid)
    }
    if (router.query.source) {
      Affiliate.setSource(router.query.source)
    }

    AOS.init({
      once: true,
      delay: 50,
      duration: 500,
      easing: 'ease-in-out',
    })

    window.addEventListener('storage', storageListener)
    return () => window.removeEventListener('storage', storageListener)
  }, [router.isReady, router.asPath])

  const isAgeGateNeeded =
    (!disableAgeGate && siteContext?.site?.age_gate) ? true : false

  const themeSite = siteContext?.site?.theme
  const theme = getTheme({
    themeSiteKey: themeSiteKey || siteContext?.key,
    theme: themeSite,
  })
  const highTopbar = siteContext?.key === 'mlb-wine-brands'; 
  const hideTopBar = hideTopBarProp || siteContext?.site?.top_bar?.hide
  
  let script =
    siteContext?.key === GOODFRIENDS ? (
      <Script
        id="klaviyo"
        // strategy="lazyOnload"
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Rdb6zU"
      />
    ) : (
      undefined
    )
  
  return (
    <SiteContext.Provider value={siteContext}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Paper
          elevation={0}
          sx={{
            marginTop: highTopbar 
              ? 4 
              : hideTopBar ? 0 : 2,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Layout
            topBarLogo={topBarLogo || siteContext?.site?.top_bar?.logo}
            topBarLogoUrl={topBarLogoUrl}
            hideTopBar={hideTopBar}
            topbarStyle={topbarStyle}
            hideFooter={hideFooter}
          >
            {isAgeGateNeeded && <AgeGate />}
            {children}
          </Layout>
        </Paper>
      </ThemeProvider>
      {script}
      <Analytics />
    </SiteContext.Provider>
  )
}