import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Link from 'next/link'
import CartChip from '@/components/CartChip'
import SearchIcon from '@mui/icons-material/Search'
import Image from 'next/image'
import { useTheme } from '@mui/material/styles'
import { useSite } from '@/src/contexts/SiteContext'
import {
  GOODFRIENDS,
  SBG_SITE_ID,
  TAKE_FIVE_ID,
  THE_MAKERS_BARN,
  DRINKAMIE
} from '@/consts/siteConsts'

const TopbarMenuOnRight = ({
  logo,
  logoUrl,
  onSidebarOpen,
}) => {
  const theme = useTheme()
  const { site } = useSite()
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
      height={'40px'}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Link href={logoUrl}>
          <a>
            <Image
              height={'40px'}
              width="100%"
              objectFit={'contain'}
              src={logo}
            />
          </a>
        </Link>
      </Box>
      <Box display="flex" flexDirection={'row'} gap={2}>
        <Box display="flex" flexDirection={'row'} gap={2} alignItems="baseline">
          {showSearchIcon(site) ? (
            <Link href="/?ref=header" href="/products?ref=header">
              <a>
                <SearchIcon sx={{ color: theme.palette.primary.main }} />
              </a>
            </Link>
          ) : null}

          {showCart(site) ? <CartChip /> : null}
          {showMenuIcon(site) ? (
            <IconButton onClick={onSidebarOpen} aria-label="Menu">
              <MenuIcon />
            </IconButton>
          ) : null}
        </Box>
        {/* <LoggedUserChip /> */}
      </Box>
    </Box>
  )
}

TopbarMenuOnRight.propTypes = {
  onSidebarOpen: PropTypes.func,
}

export default TopbarMenuOnRight

function showCart(site) {
  switch (site?.key) {
    case THE_MAKERS_BARN:
    case DRINKAMIE: 
      return false;
    default:
      return true;
  }
}

function showSearchIcon(site) {
  switch (site?.key) {
    case TAKE_FIVE_ID:   
    case SBG_SITE_ID: 
    case THE_MAKERS_BARN:
    case DRINKAMIE: 
    case GOODFRIENDS:
      return false;
    default:
      return true;
  }
}

function showMenuIcon(site) {
  switch (site?.key) {
    case TAKE_FIVE_ID:   
    case SBG_SITE_ID: 
    case DRINKAMIE: 
      return false;
    default:
      return true;
  }
}