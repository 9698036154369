import React from 'react';
import Box from '@mui/material/Box';
import Image from 'next/image'
import { Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const TopbarCheckout = ({
  logo,
}) => {
  const theme = useTheme()
  const isMd= useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
      height={'40px'}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Image height={'40px'} width="100%" objectFit={'contain'} src={logo} />
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant="h5" color={'primary'} sx={{ fontWeight: 700 }}>
          Checkout
        </Typography>
      </Box>
      {isMd && <Box display="flex" flexDirection={'row'} gap={2}></Box>}
    </Box>
  )
};

export default TopbarCheckout;
