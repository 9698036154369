
export function omitNull(obj) {
  if (!obj) {
    return obj
  }
  Object.keys(obj).filter(k => obj[k] === null).forEach(k => delete(obj[k]))
  return obj
}

export function omitUndefined(obj) {
  if (!obj) {
    return obj
  }
  Object.keys(obj).filter(k => obj[k] === undefined).forEach(k => delete(obj[k]))
  return obj
}