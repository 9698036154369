import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import { Topbar } from './components';
import { Sidebar, Footer } from '../components';
import Container from '../../common/Container';
import { pages } from '../navigation';
import { Cart } from '@/utils/cart';
import { useRouter } from 'next/router';
import { useSite } from '@/src/contexts/SiteContext';

const HideOnScroll = ({ children }) => {
  const router = useRouter();
  const trigger = useScrollTrigger();

  const [cartChanged, setCartChanged] = useState();

  const updateCartCount = () => {
    setCartChanged((prev) => (prev ? prev + 1 : 1))
    setTimeout(()=> setCartChanged(false), 3000);
  };

  useEffect(() => {
    Cart.addChangeListener(updateCartCount);
    return () => { 
      Cart.removeChangeListener(updateCartCount);
    };
  });
  
  return (
    <Slide appear={false} direction="down" in={!trigger || cartChanged}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.node.isRequired,
};

const MainLayout = ({
  topBarLogo,
  topBarLogoUrl,
  hideTopBar,
  hideFooter,
  topbarStyle,
  children,
}) => {
  const theme = useTheme();
  const { site } = useSite()
  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };
  let topBar;
  if (!hideTopBar) {
    topBar = (
      <Topbar
        logo={topBarLogo}
        logoUrl={topBarLogoUrl}
        site={site}
        topbarStyle={topbarStyle}
        onSidebarOpen={handleSidebarOpen}
      />
    ) 
  }
  
  const menuPages = site?.menu_navigation || pages;
  return (
    <Box>
      {topBar ? (
        <HideOnScroll>
          <AppBar
            position={'fixed'}
            sx={{
              backgroundColor: 
                theme.palette.background.top_bar ||
                theme.palette.background.paper,
            }}
            elevation={1}
          >
            <Container paddingY={{ xs: 1, sm: 1 }}>{topBar}</Container>
          </AppBar>
        </HideOnScroll>
      ) : null}

      <Sidebar
        onClose={handleSidebarClose}
        open={openSidebar}
        variant="temporary"
        pages={menuPages}
      />
      <main>
        <Box height={topBar ? '40px' : undefined} />
        {children}
        <Divider />
      </main>
      {/* Big bottom margin becouse of the chat icon */}
      {!hideFooter && (
        <Box
          sx={{
            backgroundColor:
              theme.palette.background.top_bar ||
              theme.palette.background.paper,
          }}
        >
          <Container paddingY={4} sx={{ paddingBottom: 8 }}>
            <Footer />
          </Container>
        </Box>
      )}
    </Box>
  )
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;  
