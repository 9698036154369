import React from 'react';
import SipjoyFooter from './SipjoyFooter';
import SbgFooter from './SbgFooter';
import { useSite } from '@/src/contexts/SiteContext';
import {
  MLB_SITE_ID,
  SBG_SITE_ID,
  TAKE_FIVE_ID,
  BEER_CAN_CHICKEN_ID,
  THE_MAKERS_BARN,
  DRINKAMIE,
} from '@/consts/siteConsts'
import MlbFooter from './MlbFooter';
import TheMakersBarnFooter from './TheMakersBarnFooter';
import { BrandingWatermarkSharp } from '@mui/icons-material';

const Footer = () => { 
  const { site } = useSite()
  if (!site) {
    return null;
  }
  const footer = site.footer
  console.log({footer});
  if (site?.key === DRINKAMIE) {
    footer.hide_facebook_link = true
  }
  switch (site.key) {
    case MLB_SITE_ID: 
      return <MlbFooter/>;
    case THE_MAKERS_BARN: 
      return <TheMakersBarnFooter/>
    case TAKE_FIVE_ID: 
    case BEER_CAN_CHICKEN_ID: 
    case SBG_SITE_ID: 
    case DRINKAMIE:
      return <SbgFooter footer={footer}/>;
    default:
      return <SipjoyFooter footer={footer}/>;
  }
};

export default Footer;
