import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';
import Link from 'next/link'

const SipjoyFooter = ({ footer }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Box
        gap={1}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={{ xs: 'center', sm: 'flex-start' }}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          gap={1}
        >
          <Link
            underline="none"
            href="/?ref=footer"
            color="textPrimary"
            variant={'subtitle2'}
          >
            Home
          </Link>
          {!footer?.hideForWineries && (
            <Link
              underline="none"
              href="/for_wineries"
              color="textPrimary"
              variant={'subtitle2'}
            >
              For wineries
            </Link>
          )}
          {!footer?.hideForDistributors && (
            <Link
              underline="none"
              component="a"
              href="https://www.grapein.com"
              color="textPrimary"
              variant={'subtitle2'}
            >
              For distributors
            </Link>
          )}
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          gap={1}
        >
          <Link
            underline="none"
            href="/privacy?ref=footer"
            color="textPrimary"
            variant={'subtitle2'}
          >
            Privacy Policy
          </Link>
          <Link
            underline="none"
            href="/terms?ref=footer"
            color="textPrimary"
            variant={'subtitle2'}
          >
            Terms of service
          </Link>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          gap={1}
        >
          {!footer?.hideSocialLinks && (
            <Box
              display="flex"
              flexDirection={'row'}
              justifyContent="space-evenly"
              width="100%"
            >
              <IconButton
                aria-label="Facebook"
                component={'a'}
                href="https://facebook.com/sipjoynet"
                target="_blank"
                sx={{ padding: '0' }}
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                aria-label="Instagram"
                component={'a'}
                href="https://www.instagram.com/sipjoynet/"
                target="_blank"
                sx={{ padding: '0' }}
              >
                <InstagramIcon />
              </IconButton>
            </Box>
          )}
          <Link
            variant="outlined"
            color="primary"
            target="blank"
            href="/contact?ref=footer"
            size="small"
            sx={{ maxWidth: '120px' }}
          >
            Contact Us
          </Link>
        </Box>
      </Box>
    </Grid>
    <Grid item xs={12} marginTop={2}>
      <Typography
        align={'center'}
        variant={'subtitle2'}
        color="textSecondary"
        gutterBottom
      >
        &copy; 2022 by SipJoy Inc. All rights reserved
      </Typography>
    </Grid>
  </Grid>
)

export default SipjoyFooter;
