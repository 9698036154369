import React from 'react'
import Box from '@mui/material/Box'
import Link from 'next/link'
import CartChip from '@/components/CartChip'
import Image from 'next/image'
import { useTheme } from '@mui/material/styles'
import { useSite } from '@/src/contexts/SiteContext'


const TopbarMenuGoodfriends = ({
  logo,
  logoUrl,
  onSidebarOpen,
}) => {
  const theme = useTheme()
  const { site } = useSite()
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
      height={'40px'}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Link href={logoUrl}>
          <a>
            <Image
              height={'40px'}
              width="100%"
              objectFit={'contain'}
              src={logo}
            />
          </a>
        </Link>
      </Box>
      <Box display="flex" flexDirection={'row'} gap={6} sx={{ marginTop: 1}} >
        <Link href="/product/goodfriends-bourbon">Shop</Link>
        <Link href="/about">About</Link>
        <Link href="/whiskey101">Whiskey101</Link>
        <Box display="flex" flexDirection={'row'} gap={2} sx={{marginTop: -1}} >
          <CartChip />
        </Box>
        {/* <LoggedUserChip /> */}
      </Box>
    </Box>
  )
}


export default TopbarMenuGoodfriends

