import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import IconButton from '@mui/material/IconButton'
import Link from 'next/link'
import { useTheme } from '@mui/material/styles'

import useMediaQuery from '@mui/material/useMediaQuery';

const MlbFooter = () => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  
  return (
    <Grid
      container
      spacing={2}
      sx={{ color: theme.palette.primary.contrastText }}
    >
      <Grid item xs={12}>
        <Box
          gap={isMd ? 1 : 6}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          width={'100%'}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={{ xs: 'center', sm: 'flex-start' }}
            gap={1}
          >
            <Link underline="none" href="/?ref=footer">
              <a>
                <Typography variant={'h6'}>Shop</Typography>
              </a>
            </Link>
            <Link
              underline="none"
              href="/faq?ref=footer"
              color="textPrimary"
              variant={'subtitle2'}
            >
              <a>
                <Typography color="secondary" variant={'body2'}>
                  FAQ
                </Typography>
              </a>
            </Link>
            <Link
              underline="none"
              href="/contact?ref=footer"
              color="textPrimary"
              variant={'subtitle2'}
            >
              <a>
                <Typography color="secondary" variant={'body2'}>
                  Contact
                </Typography>
              </a>
            </Link>
            <Link
              underline="none"
              href="/terms?ref=footer"
              color="textPrimary"
              variant={'subtitle2'}
            >
              <a>
                <Typography color="secondary" variant={'body2'}>
                  Terms of service
                </Typography>
              </a>
            </Link>
            <Link
              underline="none"
              href="/privacy?ref=footer"
              color="secondary"
              variant={'body2'}
            >
              <a>
                <Typography color="secondary" variant={'body2'}>
                  Privacy Policy
                </Typography>
              </a>
            </Link>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={{ xs: 'center', sm: 'flex-start' }}
            gap={1}
          >
            <Link underline="none" href="/about?ref=footer">
              <a>
                <Typography variant={'h6'}>About</Typography>
              </a>
            </Link>
          </Box>
          <Box
            display="flex"
            flexDirection={'column'}
            alignItems={{ xs: 'center', sm: 'flex-start' }}
            gap={1}
            sx={{ maxWidth: '200px' }}
          >
            <Typography variant={'h6'}>Our Mission</Typography>

            <Typography color="secondary" variant={'body2'}>
              Share contact information, store details, and brand content with
              your customers.
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ paddingT: 0 }}>
        <Link href="https://sipjoy.net/" target="_blank">
          <a>
            <Typography
              align={'center'}
              variant={'subtitle2'}
              gutterBottom
              sx={{
                marginLeft: isMd ? -10 : 0,
                marginTop: 15,
                fontWeight: 700,
              }}
            >
              Powered by Sipjoy
            </Typography>
          </a>
        </Link>
      </Grid>
    </Grid>
  )
}

export default MlbFooter
