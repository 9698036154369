import React from 'react';
import Box from '@mui/material/Box';
import Image from 'next/image'
import { Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const TopbarMlb = ({
}) => {
  const theme = useTheme()
  const isMd= useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  const textColor = theme.palette.primary.contrastText
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
      height={'100px'}
    >
      <Box display={'flex'} alignItems={'center'}></Box>
      <Box display={'flex'} alignItems={'center'} flexDirection="column" gap={2}>
        <Typography variant="h4" color={textColor} sx={{ fontWeight: 700 }}>
          MLB Wines
        </Typography>
        <Typography variant="body2" color={textColor}>
          Shop By Team
        </Typography>
      </Box>
      <Box display="flex" flexDirection={'row'} gap={2}></Box>
    </Box>
  )
};

export default TopbarMlb;
