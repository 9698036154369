import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from '@mui/material';
import { useRouter } from 'next/router';
import { Mixpanel } from '@/utils/mixpanel';

const SidebarNav = ({ pages, onClose }) => {
  const theme = useTheme();

  const router = useRouter();
  const [pageRefresh, setPageRefresh] = useState(false);
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    if(!router.isReady) return;
    setActiveLink(window && window.location ? window.location.pathname : '');
    setPageRefresh(false);
  }, [router.asPath, router.isReady]);


  const inEditMode = router.query.edit;
  const toggleEditMode = () => {
    if (router.query.edit) {
      delete router.query.edit;
    } else {
      router.query.edit = true;
    }
    setPageRefresh(true);
    router.push({path: router.asPath, query: router.query});
  }

  const createLink = (p, i) => {
    return (
      <Grid item xs={12} key={i}>
        <Link
          variant="body2"
          color={activeLink === p.href ? 'primary' : 'textPrimary'}
          underline={'none'}
          sx={{
            cursor: 'pointer',
            fontWeight: activeLink === p.href ? 600 : 400,
            '&:hover': {
              textDecoration: 'none',
              color: theme.palette.primary.dark,
            },
          }}
          onClick={() => {
            onClose();
            Mixpanel.track('Sidenav click', {on:p.href});
            const url = p.href.includes('?')
              ? p.href + '&ref=sidenav'
              : p.href + '?ref=sidenav';
            router.push(url);
          }}
        >
          {p.title}
        </Link>
      </Grid>
    );
  };
  
  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        onClick={() => onClose()}
      >
        <IconButton>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box paddingX={2} paddingBottom={2}>
        <Box>
          {pages.map((item, i) => {
            const adminPages = item.pages.filter((p) => p.admin);
            return (
              <Box key={i} marginBottom={4}>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    marginBottom: 1,
                    display: 'block',
                  }}
                >
                  {item.title}
                </Typography>
                <Grid container spacing={1}>
                  {item.pages
                    .filter((p) => !p.admin)
                    .map((p, i) => createLink(p, i))}
                </Grid>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired,
  onClose: PropTypes.func,
};

export default SidebarNav;
