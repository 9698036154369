export const paletteMlb = {
  background: {
    paper: '#fff',
    default: '#eff0f5',
    level2: '#f6f6f6',
    level1: '#f6f6f6',
    top_bar: '#0e1a4d',
    footer: '#0e1a4d'
  },
  mode: 'light',
  primary: {
    main: '#0e1a4d',
    contrastText: '#fff',
  },
  text: {
    primary: '#0e1a4d',
    secondary: '#878da7',
  },
  secondary: {
    main: '#878da7',
  },
};

export const components = {
  MuiButton: {
    styleOverrides: {
      label: {
        fontWeight: 600,
      },
      contained: {
        padding: '10px 40px',
        borderRadius: '40px',
      },
    },
  },
}
