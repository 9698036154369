export const paletteHormel = {
  background: {
    paper: '#272727',
    default: '#272727',
    level2: '#f5f5f5',
    level1: '#fff',
  },
  alternate: {
    main: '#960e1a',
  },
  mode: 'light',
  primary: {
    main: '#EA212D',
  },
  secondary: {
    main: '#FDCF2F',
  },
  accent: {
    green: '#849D71',
    red: '#830000',
    dark: '#3A130F'
  },
  text: {
    primary: '#FDCF2F',
    secondary: '#FDCF2F',
  },
  error: {
    main: '#ffcfcf',
  }
};

export const components = {
}