import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Badge,
  Link,
  Alert,
  Snackbar,
} from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Cart } from '@/utils/cart';
import { useRouter } from 'next/router';

const CartChip = ({color}) => {
  const theme = useTheme();
  const [errorMsg, setErrorMsg] = useState(false);
  const [open, setOpen] = useState(false);
  const [cart, setCart] = useState(Cart.getCurrentCart());
  const router = useRouter();

  let itemsInCart = 0;
  let producers = {};
  if (cart) {
    Object.keys(cart).forEach((producerKey) => {
      if (producerKey) {
        const producerCart = cart[producerKey];
        let num = 0;
        Object.values(producerCart).forEach(bottles => {
          num = num + bottles;
        });
        Object.keys(producerCart).length;
        if (num > 0) {
          producers[producerKey] = num;
          itemsInCart = itemsInCart + num;
        }
      }
    });
  }

  const updateCartCount = () => {
    const cart = Cart.getCurrentCart();
    setCart(cart);
  };

  useEffect(() => {
    Cart.addChangeListener(updateCartCount);
    return () => { 
      Cart.removeChangeListener(updateCartCount);
    };
  });

  const navigateProducerCheckoutUrl = (producerKey) => {
    router.push(`/checkout/${producerKey}`);
    setOpen(false);
  };

  const handleClick = () => {
    const producersKey = Object.keys(producers);
    if (producersKey.length === 1 ) {
      navigateProducerCheckoutUrl(producersKey[0]);
    }
    else if (producersKey.length > 1) {
      setOpen((prev) => !prev);
    }
    else {
      
      setErrorMsg('No items in cart')
    }
  };

  return (
    <>
      {errorMsg && (
        <Snackbar
          autoHideDuration={4000}
          open={true}
          onClose={() => setErrorMsg(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity={'error'}>{errorMsg}</Alert>
        </Snackbar>
      )}
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Cart
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`You have ${Object.keys(producers).length} shopping carts`}
          </DialogContentText>
          <Box marginTop={2}>
            {Object.keys(producers)
              .sort()
              .map((producerKey, key) => {
                const items = producers[producerKey]
                return (
                  <Box key={key} marginTop={3}>
                    <Badge badgeContent={items} color="primary">
                      <Button
                        variant="outlined"
                        endIcon={<ShoppingCartOutlinedIcon />}
                        onClick={() => navigateProducerCheckoutUrl(producerKey)}
                      >
                        {producerKey}
                      </Button>
                    </Badge>
                  </Box>
                )
              })}
          </Box>
        </DialogContent>
      </Dialog>

      <Badge
        color={color ? undefined : 'primary'}
        badgeContent={itemsInCart}
        sx={{
          cursor: itemsInCart && 'pointer',
          color: itemsInCart
            ? color || theme.palette.primary.main
            : theme.palette.text.secondary,
          marginTop: itemsInCart ? 1 : 0,
        }}
        onClick={handleClick}
      >
        <ShoppingCartOutlinedIcon
          sx={{
            color: itemsInCart
              ? color || theme.palette.primary.main
              : theme.palette.text.secondary,
          }}
        />
      </Badge>
    </>
  )
};


CartChip.propTypes = {
  color: PropTypes.string,
};
export default CartChip;
