import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';
import Link from 'next/link'
import { useTheme } from '@mui/material/styles';

const SbgFooter = ({footer}) => {
  const theme = useTheme()

  const hideFacebookLink = footer?.hide_facebook_link ? true : false
  const facebookLink = footer?.facebook_link || "https://facebook.com/sipjoynet"
  const instagramLink = footer?.instagram_link || "https://www.instagram.com/sipjoynet"
  const textColor = theme.palette.text.footer || "textPrimary"
  return (
    <Grid container spacing={2} sx={{ color: textColor }}>
      <Grid item xs={12}>
        <Box
          gap={1}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          width={'100%'}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={{ xs: 'center', sm: 'flex-start' }}
            gap={1}
          >
            <Link
              underline="none"
              href="/?ref=footer"
              variant={'subtitle2'}
            >
              Home
            </Link>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={{ xs: 'center', sm: 'flex-start' }}
            gap={1}
          >
            <Link
              underline="none"
              href="/privacy?ref=footer"
              variant={'subtitle2'}
            >
              Privacy Policy
            </Link>
            <Link
              underline="none"
              href="/terms?ref=footer"
              variant={'subtitle2'}
            >
              Terms of service
            </Link>
            <Link href="https://sipjoy.net/" target="_blank">
              <a>
                <Typography
                  align={'center'}
                  variant={'subtitle2'}
                  color={theme.palette.secondary.main}
                  gutterBottom
                  sx={{ marginTop: 3, fontWeight: 700 }}
                >
                  Powered by Sipjoy
                </Typography>
              </a>
            </Link>
          </Box>
          <Box
            display="flex"
            flexDirection={'column'}
            alignItems={{ xs: 'center', sm: 'flex-start' }}
            gap={1}
          >
            <Box
              display="flex"
              flexDirection={'row'}
              justifyContent="space-evenly"
              width="100%"
            >
              {!hideFacebookLink ? (

                <IconButton
                  aria-label="Facebook"
                  component={'a'}
                  href={facebookLink}
                  target="_blank"
                  sx={{ padding: '0', color: theme.palette.secondary.main }}
                >
                  <FacebookIcon />
                </IconButton>
              ) : null}
              <IconButton
                aria-label="Instagram"
                component={'a'}
                href={instagramLink}
                target="_blank"
                sx={{ padding: '0', color: theme.palette.secondary.main }}
              >
                <InstagramIcon />
              </IconButton>
            </Box>
            <Link
              variant="outlined"
              color={textColor}
              target="blank"
              href="/contact?ref=footer"
              size="small"
              sx={{ maxWidth: '120px' }}
            >
              Contact Us
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} marginTop={2}></Grid>
    </Grid>
  )
}

export default SbgFooter;
