import React, { useState } from 'react'
import {
  Button,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/system'

const LOCAL_STORAGE_KEY_AGE_GATE = 'age_gate'
const AgeGate = () => {
  const theme = useTheme()
  const [open, setOpen] = useState(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(LOCAL_STORAGE_KEY_AGE_GATE) !== 'true'
    }
    return false;
  })
  const [under21MsgVisible, setUnder21MsgVisible] = useState()

  const closePopup = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    if (typeof window !== 'undefined') {
      localStorage.setItem(LOCAL_STORAGE_KEY_AGE_GATE, 'true')
    }
    setOpen(false)
  }
  return (
    <>
      <Dialog onClose={(event, reason) => closePopup(event, reason)} open={open}>
        <DialogContent sx={{ margin: 0, padding: 0 , backgroundColor: theme.palette.background}}>
          <CardContent>
            <Typography variant="h5">Are you 21 or older?</Typography>
            <Typography variant="body2" color="text.secondary">
              You must be over the legal drinking age to visit this site.
            </Typography>
          </CardContent>
          <Box
            sx={{
              padding: '10px 20px',
              backgroundColor: theme.palette.background.level1,
            }}
          >
            <CardActions
              sx={{
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="outlined"
                onClick={() => setUnder21MsgVisible(true)}
              >
                NO
              </Button>
              <Button variant="contained" onClick={() => closePopup()}>
                YES
              </Button>
            </CardActions>
            {under21MsgVisible && (
              <Typography>Thank you for your honesty.</Typography>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AgeGate
