import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Link from 'next/link'
import CartChip from '@/components/CartChip';
import SearchIcon from '@mui/icons-material/Search';
import PartnersSupportClient from '@/utils/partnersSupportClient';
import Image from 'next/image'
import TopbarMenuOnRight from './TopbarMenuOnRight';
import { useSite } from '@/src/contexts/SiteContext';
import TopbarCheckout from './TopbarCheckout';
import {
  MLB_SITE_ID,
  SBG_SITE_ID,
  TAKE_FIVE_ID,
  BEER_CAN_CHICKEN_ID,
  THE_MAKERS_BARN,
  GOODFRIENDS,
  DRINKAMIE,
  TEQUILA_ZERO
} from '@/consts/siteConsts'
import TopbarMlb from './TopbarMlb';
import TopbarMenuGoodfriends from './TopbarMenuGoodfriends';
import TopbarMenuTequilaZero from './TopbarMenuTequilaZero';

export const TOP_BAR_CHECKOUT = 'checkout'
const Topbar = ({
  onSidebarOpen,
  logo,
  logoUrl = '/?ref=header',
  topbarStyle
}) => {
  const theme = useTheme()
  const { site } = useSite()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  
  const logoImg = logo || PartnersSupportClient.getLogo();
  if (topbarStyle === TOP_BAR_CHECKOUT) {
    return <TopbarCheckout logo={logoImg} />
  }
  switch (site?.key) {
    case MLB_SITE_ID:   
      return <TopbarMlb />
    case TEQUILA_ZERO:
      return isMd ? (
        <TopbarMenuTequilaZero
          logo={logoImg}
          logoUrl={logoUrl}
          onSidebarOpen={onSidebarOpen}
        />
      ) : (
        <TopbarMenuOnRight
          logo={logoImg}
          logoUrl={logoUrl}
          onSidebarOpen={onSidebarOpen}
        />
      )
    case GOODFRIENDS:
      return isMd ? (
        <TopbarMenuGoodfriends
          logo={logoImg}
          logoUrl={logoUrl}
          onSidebarOpen={onSidebarOpen}
        />
      ) : (
        <TopbarMenuOnRight
          logo={logoImg}
          logoUrl={logoUrl}
          onSidebarOpen={onSidebarOpen}
        />
      )
    case BEER_CAN_CHICKEN_ID:   
    case TAKE_FIVE_ID:   
    case SBG_SITE_ID:  
    case THE_MAKERS_BARN: 
    case DRINKAMIE:
      return (
        <TopbarMenuOnRight
          logo={logoImg}
          logoUrl='https://drinkamie.com/'
          onSidebarOpen={onSidebarOpen}
        />
      )
    default:
      return (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Box marginRight={{ xs: 1, sm: 2 }}>
              <IconButton onClick={onSidebarOpen} aria-label="Menu">
                <MenuIcon />
              </IconButton>
            </Box>
            <Link href="/?ref=header">
              <a>
                <Image
                  height={'30px'}
                  width="100%"
                  objectFit={'contain'}
                  src={logoImg}
                />
              </a>
            </Link>
          </Box>
          <Box display="flex" flexDirection={'row'} gap={2} alignItems="flex-end">
            <Link href="/?ref=header" href="/products?ref=header">
              <a>
                <SearchIcon sx={{ color: theme.palette.primary.main }} />
              </a>
            </Link>
            <CartChip />
          </Box>
        </Box>
      );
  }
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
