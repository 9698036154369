export const grapin = {
  alternate: {
    main: '#f7f9fc',
    dark: '#edf1f7',
  },
  alternateBackground: '#f6f9fc',
  backgroundImageCover: '#ccdfe5',
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',
  primary: {
    main: '#6b3140',
    light: '#ff767c',
    lightBackground: 'rgba(252, 92, 100, 0.1)',
    dark: '#6b3140',
    contrastText: '#fff',
  },
  secondary: {
    main: '#93cb61',
    light: '#f0ffe3',
    dark: '#686e64',
    contrastText: '#fff',
  },
  text: {
    primary: '#2d3748',
    secondary: '#646e73',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#fff',
    default: '#fff',
    level2: '#f6f6f6',
    level1: '#fff',
  },
};

export const components = {
  MuiButton: {
    styleOverrides: {
      label: {
        fontWeight: 600,
      },
      contained: {
        color: grapin.primary.main,
        backgroundColor: 'white',
        fontWeight: 600,
        border: '1px solid lightgrey',
        '&:hover, &.Mui-focusVisible': {
          color: 'white',
        },
      },
      containedSecondary: { color: 'white' },
    },
  },
}
