import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import { paletteMlb, components as paletteMlbComponents } from './palette--mlb';
import { grapin, components as grapinComponents } from './palette--grapin';
import {
  paletteHormel,
  components as hormelComponents,
} from './palette--hormel'

import { HORMEL_SITE_ID, MLB_SITE_ID } from '@/consts/siteConsts';
import { omitNull } from '@/utils/objectUtils';


const getTheme = ({themeSiteKey, theme}) => {
  let p, fontFamily, components, typography;
  console.log({components: theme?.mui_components});
  if (theme?.palette) {
    const themeWithoutNull = omitNull(theme?.palette)
    p = {
      ...grapin,
      mode: 'light',
      ...themeWithoutNull,
    }
    typography = theme?.typography
    fontFamily = typography?.fontFamily || '"Inter", sans-serif'
    components = { ...(theme?.mui_components || {}) }

  } else if (themeSiteKey === HORMEL_SITE_ID) {
    p = paletteHormel;
    fontFamily = 'ArialNarrowBold, '
    components = hormelComponents
  } else if (themeSiteKey === MLB_SITE_ID) {
    p = paletteMlb;
    fontFamily = 'Questrial, sans-serif'
    components = paletteMlbComponents;
  } else {
    p = grapin;
    fontFamily = '"Inter", sans-serif'
    components = grapinComponents
  }

  return responsiveFontSizes(
    createTheme({
      palette: p,
      layout: {
        contentWidth: 1236,
      },
      shadows: shadows(),
      typography: {
        fontFamily: fontFamily || undefined,
        fontWeight: typography?.fontWeight || undefined,
        fontSize: typography?.fontSize || undefined,
        button: {
          textTransform: 'none',
          fontWeight: 'medium',
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components,
    }),
  )
};

export default getTheme;
